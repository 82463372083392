
const validate = (productDetails) => {

    const notSetMessage = "Please input ";

    const isSet = [
        ["name", "the product name."],
        ["VendorProductNo", "your product code."],
        ["casebarcodecurrent", "the product case barcode."],
        ["itembarcodecurrent", "the inner barcode. If the product only has one barcode. Just set this to the case barcode."],
        ["PricePO", "the price you sell the product at."]
    ]

    for(const [key, msg] of isSet) {
        if(!productDetails[key]) {
            return { isValid: false, msg: notSetMessage + msg }
        }
    }

    if(productDetails.PriceStd && isNaN(productDetails.PriceStd)) {
        return { isValid: false, msg: "RRP must be a number." }
    }
    if(isNaN(productDetails.PricePO)) {
        return { isValid: false, msg: "Pack Purchase Price must be a number." }
    }
    if(productDetails.Order_Pack && isNaN(productDetails.Order_Pack)) {
        return { isValid: false, msg: "Pack size must be a number. (e.g. Case of 6 -> 6)" }
    }


    return { isValid: true }

}

const ProductValidator = {
    validate
}

export default ProductValidator;
