import createRequestRequest from "../helpDesk/createRequestRequest";
import {request} from "../../../../../res/rest/restRequest";

const newProductRequest = () => {

    const builder = {};

    builder.data = (data) => {
        builder.reqData = data;
        return builder;
    }

    const sendRequest = async (session, name, code) => {
        const req = createRequestRequest()
            .setSummary("New product request. Product name: " + name + ", Code: " + code)
            .setRequestTypeID(1000051)
            .setRoleID(1000102)
            .setConfidentialityType("I")
            .setPriority("3");

        await req.sendIt(session);
    }

    const getImportData = (session) => {
        const i = builder.reqData;
        return {
            "C_BPartner_ID": session.bPartnerID,
            "BPartner_Value": session.businessCode,
            "Description": i.Name,
            ...i
        }
    }

    builder.sendIt = async (session) => {
        await request.model("i_product")
            .data(getImportData(session))
            .post(session);

        await sendRequest(session, builder.reqData.name, builder.reqData.VendorProductNo);
        return "done";
    }

    return builder;
}

export default newProductRequest;
